import React from "react";
import "./../styles/landing-page.scss";
import SevenSegments from "./SevenSegments";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();
  const handleClick = () => navigate("/course10001");
  return (
    <section className="landing-page">
      <div className="home">
        <div className="logo">
          <SevenSegments />
          <h1>SENSEI</h1>
        </div>
        <div className="featured">
          <h2>Featured Course</h2>
          <button className="featured-course" onClick={handleClick}>
            Introduction to Web Designing
          </button>
        </div>
      </div>
    </section>
  );
};

export default LandingPage;
