import React from "react";
import "./../../styles/course.scss";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDisplay } from "@fortawesome/free-solid-svg-icons";
import HtmlAnatomy from "./../../images/course10001/html-anatomy.png";
import HtmlAttribute from "./../../images/course10001/html-attribute.png";

const Course10001 = () => {
  const navigate = useNavigate();
  const handleClick = () => navigate("/demo10001");
  return (
    <section className="course">
      <h1>Kickstart Web Design</h1>
      <article>
        <p>
          We believe in <strong>LEARNING THROUGH PRACTICE</strong>.
        </p>
        <p>
          No matter how basic a topic may seem, we will not ignore any of it.
          <br />
          Every little detail matters, and through a continuous process of
          learning little details, is how we become bigger.
        </p>
        <p>
          10 days would by no means be enough to be a master at something.
          <br />
          But, it is enough to understand the gist of things and how things
          work.
          <br />
          And, every topic we learn will be accompanied by a practical
          application.
          <br />
          Hence, it is certain that we would at least{" "}
          <strong>BE A GREAT BEGINNER</strong>.
        </p>
        <p>
          <strong>Web Design</strong>, as we are concerned in this course, is
          the technology that comprises of skills and insfrastructures for the
          production and maintenance of the front-end (the visible end, or
          client side) design of a web application.
        </p>
        <p>
          The <strong>DEMO</strong> is exactly what we will build throughout{" "}
          <strong>Kickstart Web Design</strong> with our own content,
          accompanied by our learning and daily effort.
          <br />
          Also, we will learn more than what we build.
          <br />
          Click the <i>DEMO</i> button to view the demo.
        </p>
      </article>
      <button className="demo" onClick={handleClick}>
        <FontAwesomeIcon icon={faDisplay} /> DEMO
      </button>
      <p className="syllabus-intro">
        The following is the syllabus that we will follow through the course of
        10 days.
      </p>
      <ol>
        <li className="day">
          <h2>Day 1: Introduction</h2>
          <ol>
            <li>
              <h3>1.1. Web Browser</h3>
              <article>
                <p>
                  The <strong>World Wide Web</strong> (WWW or simply the Web) is
                  an information system that enables content sharing over the
                  Internet through user-friendly ways meant to appeal to users
                  beyond IT specialists and hobbyists.{" "}
                  <cite>
                    [
                    <a
                      href="https://en.wikipedia.org/wiki/World_Wide_Web"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Wikipedia: World Wide Web
                    </a>
                    ]
                  </cite>
                </p>
                <p>
                  A <strong>Web Browser</strong> is an application for accessing
                  websites and the Internet. When a user requests a web page
                  from a particular website, the browser retrieves its files
                  from a web server and then displays the page on the user's
                  screen. Browsers are used on a range of devices, including
                  desktops, laptops, tablets, and smartphones.{" "}
                  <cite>
                    [
                    <a
                      href="https://en.wikipedia.org/wiki/Web_browser"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Wikipedia: Web browser
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>1.2. Web Design</h3>
              <article>
                <p>
                  A <strong>Website</strong> is a collection of web pages and
                  related content that is identified by a common domain name and
                  published on at least one web server. Websites are typically
                  dedicated to a particular topic or purpose, such as news,
                  education, commerce, entertainment or social networking.{" "}
                  <cite>
                    [
                    <a
                      href="https://en.wikipedia.org/wiki/Website"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Wikipedia: Website
                    </a>
                    ]
                  </cite>
                </p>
                <p>
                  <strong>Web Design</strong> encompasses many different skills
                  and disciplines in the production and maintenance of websites.
                  The term "web design" is normally used to describe the design
                  process relating to the front-end (client side) design of a
                  website.{" "}
                  <cite>
                    [
                    <a
                      href="https://en.wikipedia.org/wiki/Web_design"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Wikipedia: Web design
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>1.1. HTML</h3>
              <article>
                <p>
                  A <strong>Markup Language</strong> is a set of rules that
                  defines how the layout and presentation of text and images
                  should appear in a digital document.{" "}
                  <cite>
                    [
                    <a
                      href="https://www.semrush.com/blog/markup-language/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Semrush: What Is a Markup Language?
                    </a>
                    ]
                  </cite>
                </p>
                <p>
                  The <strong>HyperText Markup Language</strong> or HTML is the
                  standard markup language for documents designed to be
                  displayed in a web browser. It defines the content and
                  structure of web content.
                  <br />
                  It is often assisted by technologies such as Cascading Style
                  Sheets (CSS) and scripting languages such as JavaScript.{" "}
                  <cite>
                    [
                    <a
                      href="https://en.wikipedia.org/wiki/HTML"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Wikipedia: HTML
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>1.2. Anatomy of an HMTL Element</h3>
              <article>
                <p>
                  HTML consists of a series of <strong>elements</strong>, which
                  we use to enclose, or wrap, different parts of the content to
                  make it appear a certain way, or act a certain way.
                  <img
                    style={{
                      display: "block",
                      margin: "1rem 0",
                      height: "140px",
                      width: "auto",
                    }}
                    src={HtmlAnatomy}
                    alt="Anatomy"
                  />
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Learn/Getting_started_with_the_web/HTML_basics"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: HTML basics
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>1.3. Attribute</h3>
              <article>
                <p>
                  Elements can also have attributes that contain extra
                  information about the element that that don't appear in the
                  actual content.
                  <br />
                  Attributes look like the following:
                  <img
                    style={{
                      display: "block",
                      margin: "1rem 0",
                      height: "auto",
                      width: "720px",
                    }}
                    src={HtmlAttribute}
                    alt="Anatomy"
                  />
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Learn/Getting_started_with_the_web/HTML_basics"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: HTML basics
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>1.4. Nesting Elements</h3>
              <article>
                <p>
                  We can put elements inside other elements which is called
                  nesting.{" "}
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Learn/Getting_started_with_the_web/HTML_basics"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: HTML basics
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>1.5. Void Element</h3>
              <article>
                <p>
                  A void element is an element in HTML that cannot have any
                  child nodes (i.e., nested elements or text nodes).
                  <br />
                  Void elements only have a start tag; end tags must not be
                  specified for void elements.
                </p>
                <p>
                  Some void elements in HTML are:
                  <ul>
                    <li>&lt;img&gt;</li>
                    <li>&lt;hr&gt;</li>
                    <li>&lt;br&gt;</li>
                    <li>&lt;input&gt;</li>
                  </ul>
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Glossary/Void_element"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: Void element
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>
                1.6. <code>&lt;!doctype&gt;</code> Declaration
              </h3>
              <article>
                <p>
                  All HTML documents must start with a{" "}
                  <code>&lt;!doctype&gt;</code> declaration.
                  <br />
                  The declaration is not an HTML tag. It is an "information" to
                  the browser about what document type to expect.
                </p>
                <p>
                  In HTML 5, the declaration is simple, that is:
                  <br />
                  <code style={{ fontWeight: "400" }}>
                    &lt;!doctype html&gt;
                  </code>
                  <br />
                  <cite>
                    [
                    <a
                      href="https://www.w3schools.com/tags/tag_doctype.asp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      W3Schoos: HTML &lt;!DOCTYPE&gt; Declaration
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>
                1.7. HTML <code>&lt;!-- comment --&gt;</code> Tag
              </h3>
              <article>
                <p>
                  The comment tag is used to insert comments in the source code.
                  <br />
                  Comments are not displayed in the browsers.
                </p>
                <p>
                  We can use comments to explain our code, which can help us
                  when we edit the source code at a later date.
                  <br />
                  This is especially useful if we have a lot of code.{" "}
                  <cite>
                    [
                    <a
                      href="https://www.w3schools.com/tags/tag_comment.asp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      W3Schoos: HTML &lt;!--...--&gt; Tag
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>1.8. CSS</h3>
              <article>
                <p>
                  A <strong>Style Sheet Language</strong>, or style language, is
                  a computer language that expresses the presentation of
                  structured documents.{" "}
                  <cite>
                    [
                    <a
                      href="https://en.wikipedia.org/wiki/Style_sheet_language"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Wikipedia: Style sheet language
                    </a>
                    ]
                  </cite>
                </p>
                <p>
                  <strong>Cascading Style Sheets (CSS)</strong> is a style sheet
                  language used for specifying the presentation and styling of a
                  document written in a markup language such as HTML or XML.
                  <br />
                  CSS is a cornerstone technology of the World Wide Web,
                  alongside HTML and JavaScript.{" "}
                  <cite>
                    [
                    <a
                      href="https://en.wikipedia.org/wiki/CSS"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Wikipedia: CSS
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
          </ol>
        </li>
        <li>
          <h2>Day 2: Inspect Element</h2>
          <ol>
            <li>
              <h3>2.1. Heading</h3>
              <article>
                <p>
                  HTML headings are titles or subtitles that you want to display
                  on a webpage.
                  <br />
                  HTML headings are defined with the <code>
                    &lt;h1&gt;
                  </code> to <code>&lt;h6&gt;</code> tags.{" "}
                  <code>&lt;h1&gt;</code> defines the most important heading.{" "}
                  <code>&lt;h6&gt;</code> defines the least important heading.{" "}
                  <cite>
                    [
                    <a
                      href="https://www.w3schools.com/html/html_headings.asp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      W3Schools: HTML Headings
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>2.2. Paragraph</h3>
              <article>
                <p>
                  A paragraph always starts on a new line, and is usually a
                  block of text.
                  <br />
                  The HTML <code>&lt;p&gt;</code> element defines a paragraph.{" "}
                  <cite>
                    [
                    <a
                      href="https://www.w3schools.com/html/html_paragraphs.asp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      W3Schools: HTML Paragraphs
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>2.3. Image</h3>
              <article>
                <p>
                  The HTML <code>&lt;img&gt;</code> tag is used to embed an
                  image in a web page.
                  <br />
                  Images are not technically inserted into a web page; images
                  are linked to web pages. The <code>&lt;img&gt;</code> tag
                  creates a holding space for the referenced image.
                  <br />
                  The <code>&lt;img&gt;</code> tag is empty, it contains
                  attributes only, and does not have a closing tag.{" "}
                  <cite>
                    [
                    <a
                      href="https://www.w3schools.com/html/html_images.asp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      W3Schools: HTML Images
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>2.4. Inspect Element</h3>
              <article>
                <p>
                  <strong>Inspect Element</strong> is a feature of modern web
                  browsers that enables anyone to view and edit a website’s
                  source code, including its HTML, CSS, JavaScript, and media
                  files. When the source code is modified with the inspect tool,
                  the changes are shown live inside the browser window.{" "}
                  <cite>
                    [
                    <a
                      href="https://blog.hubspot.com/website/how-to-inspect#:~:text=Inspect%20element%20is%20a%20feature,live%20inside%20the%20browser%20window."
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      HubSpot: How to Use Inspect Element
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
          </ol>
        </li>
        <li>
          <h2>Day 3: Flex Layout</h2>
          <ol>
            <li>
              <h3>3.1. Flexbox</h3>
              <article>
                <p>
                  The flexible box layout module, usually referred to as
                  flexbox, is a <strong>one-dimensional layout model</strong>,
                  and offers space distribution between items and powerful
                  alignment capabilities.
                  <br />
                  When we describe flexbox as being one-dimensional we are
                  describing the fact that flexbox deals with layout in one
                  dimension at a time — either as a row or as a column.{" "}
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_flexible_box_layout/Basic_concepts_of_flexbox"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: Basic concepts of flexbox
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>3.2. Aligning Items in a Flex Container</h3>
              <article>
                <p>
                  One of the reasons that flexbox quickly caught the interest of
                  web developers is that it brought proper alignment
                  capabilities to the web for the first time.
                  <br />
                  It enables proper vertical alignment, so we can at last easily
                  center a box.{" "}
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_flexible_box_layout/Aligning_items_in_a_flex_container"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: Aligning items in a flex container
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
          </ol>
        </li>
        <li>
          <h2>Day 4: List</h2>
          <ol>
            <li>
              <h3>4.1. HTML Lists</h3>
              <article>
                <p>
                  HTML lists allow web developers to group a set of related
                  items in lists.{" "}
                  <cite>
                    [
                    <a
                      href="https://www.w3schools.com/html/html_lists.asp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      W3Schools: HTML Lists
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>4.2. Unordered List</h3>
              <article>
                <p>
                  An unordered list starts with the <code>&lt;ul&gt;</code> tag.
                  Each list item starts with the <code>&lt;li&gt;</code> tag.
                  The list items will be marked with bullets (small black
                  circles) by default.{" "}
                  <cite>
                    [
                    <a
                      href="https://www.w3schools.com/html/html_lists_unordered.asp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      W3Schools: Unordered HTML List
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>4.3. Ordered List</h3>
              <article>
                <p>
                  An ordered list starts with the <code>&lt;ol&gt;</code> tag.
                  Each list item starts with the <code>&lt;li&gt;</code> tag.
                  The list items will be marked with numbers by default.{" "}
                  <cite>
                    [
                    <a
                      href="https://www.w3schools.com/html/html_lists_ordered.asp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      W3Schools: HTML Ordered Lists
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
          </ol>
        </li>
        <li>
          <h2>Day 5: Table</h2>
          <ol>
            <li>
              <h3>
                5.1. The <code>&lt;table&gt;</code> Element
              </h3>
              <article>
                <p>
                  The <code>&lt;table&gt;</code> HTML element represents tabular
                  data—that is, information presented in a two-dimensional table
                  comprised of rows and columns of cells containing data.{" "}
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/table"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: The Table element
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>
                5.2. The <code>&lt;thead&gt;</code> Element
              </h3>
              <article>
                <p>
                  The <code>&lt;thead&gt;</code> HTML element encapsulates a set
                  of table rows (<code>&lt;tr&gt;</code> elements), indicating
                  that they comprise the head of a table with information about
                  the table's columns. This is usually in the form of column
                  headers (<code>&lt;th&gt;</code> elements).{" "}
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/thead"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: The Table Head element
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>
                5.3. The <code>&lt;tbody&gt;</code> Element
              </h3>
              <article>
                <p>
                  The <code>&lt;tbody&gt;</code> HTML element encapsulates a set
                  of table rows (<code>&lt;tr&gt;</code> elements), indicating
                  that they comprise the body of a table's (main) data.
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/tbody"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: The Table Body element
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>
                5.4. The <code>&lt;tr&gt;</code> Element
              </h3>
              <article>
                <p>
                  The <code>&lt;tr&gt;</code> HTML element defines a{" "}
                  <strong>row of cells</strong> in a table. The row's cells can
                  then be established using a mix of <code>&lt;td&gt;</code>{" "}
                  (data cell) and <code>&lt;th&gt;</code> (header cell)
                  elements.{" "}
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/tr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: The Table Row element
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>
                5.5. The <code>&lt;th&gt;</code> Element
              </h3>
              <article>
                <p>
                  The <code>&lt;th&gt;</code> HTML element defines a cell as the{" "}
                  <strong>header</strong> of a group of table cells and may be
                  used as a child of the <code>&lt;tr&gt;</code> element. The
                  exact nature of this group is defined by the scope and headers
                  attributes.{" "}
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/th"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: The Table Header element
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>
                5.6. The <code>&lt;td&gt;</code> Element
              </h3>
              <article>
                <p>
                  The <code>&lt;td&gt;</code> HTML element defines a cell of a
                  table that contains <strong>data</strong> and may be used as a
                  child of the <code>&lt;td&gt;</code> element.{" "}
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/td"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: The Table Data Cell element
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
          </ol>
        </li>
        <li>
          <h2>Day 6: Grid Layout</h2>
          <ol>
            <li>
              <h3>6.1. Grid Layout</h3>
              <article>
                <p>
                  CSS grid layout introduces a{" "}
                  <strong>two-dimensional grid system</strong> to CSS. Grids can
                  be used to lay out major page areas or small user interface
                  elements.{" "}
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_grid_layout/Basic_concepts_of_grid_layout"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: Basic concepts of grid layout
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>6.2. Line-based Placement</h3>
              <article>
                <p>
                  Starting our exploration of grid with numbered lines is the
                  most logical place to begin because when we use grid layout,
                  we always have numbered lines.
                  <br />
                  The lines are numbered for columns and rows, and are indexed
                  from 1.{" "}
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_grid_layout/Grid_layout_using_line-based_placement"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: Grid layout using line-based placement
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
          </ol>
        </li>
        <li>
          <h2>Day 7: Absolute Positioning</h2>
          <ol>
            <li>
              <h3>
                7.1. The "<code>position</code>" Property
              </h3>
              <article>
                <p>
                  The position CSS property sets how an element is positioned in
                  a document.
                </p>
                <p>
                  The values that position can have are:
                  <ul>
                    <li>static</li>
                    <li>relative</li>
                    <li>absolute</li>
                    <li>fixed</li>
                    <li>sticky</li>
                  </ul>
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS/position"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: position
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>
                7.2. "<code>position: absolute</code>"
              </h3>
              <article>
                <p>
                  The element is removed from the normal document flow, and no
                  space is created for the element in the page layout.
                  <br />
                  The element is positioned relative to its closest positioned
                  ancestor (if any) or to the initial containing block. Its
                  final position is determined by the values of top, right,
                  bottom, and left.{" "}
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS/position"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: position
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
          </ol>
        </li>
        <li>
          <h2>Day 8: Links and Icons</h2>
          <ol>
            <li>
              <h3>8.1. Hyperlink</h3>
              <article>
                <p>
                  Hyperlinks allow us to link documents to other documents or
                  resources, link to specific parts of documents, or make apps
                  available at a web address.
                  <br />
                  Almost any web content can be converted to a link so that when
                  clicked or otherwise activated the web browser goes to another
                  web address.{" "}
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Learn/HTML/Introduction_to_HTML/Creating_hyperlinks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: Creating hyperlinks
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>
                8.2. The <code>&lt;a&gt;</code> Element
              </h3>
              <article>
                <p>
                  The <code>&lt;a&gt;</code> HTML element (or anchor element),
                  with its <code>href</code> attribute, creates a hyperlink to
                  web pages, files, email addresses, locations in the same page,
                  or anything else a URL can address.{" "}
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Learn/HTML/Introduction_to_HTML/Creating_hyperlinks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: Creating hyperlinks
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>8.3. Icons</h3>
              <article>
                <p>
                  Icons in web design are small elements only in size, but not
                  in importance or significance. They are needed to indicate
                  information.
                  <br />
                  These are visual anchors that help capture the user’s
                  attention and direct them to perform the targeted action.
                </p>
                <p>
                  Icons help to:
                  <ul>
                    <li>attract attention;</li>
                    <li>understand the meaning;</li>
                    <li>navigate the interface;</li>
                    <li>save visual space;</li>
                    <li>make a connection with the user.</li>
                  </ul>
                  <cite>
                    [
                    <a
                      href="https://medium.com/outcrowd/icons-in-web-design-824f57cb2db0"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Medium: Icons in Web Design
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
            <li>
              <h3>8.4. Font Awesome</h3>
              <article>
                <p>
                  Font Awesome is the Internet's{" "}
                  <strong>icon library and toolkit</strong>, used by millions of
                  designers, developers, and content creators.{" "}
                  <cite>
                    [
                    <a
                      href="https://fontawesome.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Font Awesome
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
          </ol>
        </li>
        <li>
          <h2>Day 9: Print Media Query</h2>
          <ol>
            <li>
              <h3>
                9.1. The "<code>print</code>" Media Query
              </h3>
              <article>
                <p>
                  We can use the CSS <code>@media</code> at-rule to set
                  different styles for our webpage when it is printed on paper
                  or as a PDF versus when it is displayed on the screen.
                  <br />
                  The <code>print</code> media type sets the styles for printed
                  media; these styles will only be used for printed content.{" "}
                  <cite>
                    [
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_media_queries/Printing"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDN Web Docs: Using a print style sheet
                    </a>
                    ]
                  </cite>
                </p>
              </article>
            </li>
          </ol>
        </li>
        <li>
          <h2>Day 10: Revision and Foresight</h2>
          <ol>
            <li>
              <h3>10.1. Revision</h3>
              <article>
                <p>A thorough revision on what we learnt so far.</p>
              </article>
            </li>
            <li>
              <h3>10.2. Foresight</h3>
              <article>
                <p>
                  Foresights on how we can implement and further enhance the
                  knowledge we gained.
                </p>
              </article>
            </li>
          </ol>
        </li>
      </ol>
    </section>
  );
};

export default Course10001;
