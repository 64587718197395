import React from "react";
import "./../../styles/demo10001.scss";
import ProfilePic from "./../../images/demo10001/profile-pic.jpg";
import Potrait1 from "./../../images/demo10001/potrait1.jpg";
import Potrait2 from "./../../images/demo10001/potrait2.jpg";
import Landscape1 from "./../../images/demo10001/landscape1.jpg";
import Landscape2 from "./../../images/demo10001/landscape2.jpg";
import Hobby1 from "./../../images/demo10001/hobby1.jpg";
import Hobby2 from "./../../images/demo10001/hobby2.jpg";
import Hobby3 from "./../../images/demo10001/hobby3.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBehance,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Demo10001 = () => {
  return (
    <section className="demo10001">
      <article className="intro">
        <img src={ProfilePic} alt="PP" />
        <div className="title-and-intro">
          <div className="title">
            <h1>Usha Upadhyaya</h1>
            <h3>Graphic Designer & Artist</h3>
          </div>
          <div className="intro">
            <p>
              I am a seasoned graphic designer specializing in various domains
              of design, marketing & branding. My exposure in design comes from
              my artistic learning curve of mandalas, sketches & paintings.
            </p>
            <p>
              I have expertise in designing social media images and videos,
              notices, flexes, newspaper ads, meta ads, Google ads, business
              cards, and more.
            </p>
          </div>
        </div>
      </article>
      <article className="pro-skills">
        <h2>Professional Skills</h2>
        <ul>
          <li>Editing and manipulating images using Adobe Photoshop.</li>
          <li>Designing graphics with templates and tools in Canva.</li>
          <li>
            Adding effects, texts, music, etc. to videos utilizing video editing
            software such as Capcut.
          </li>
          <li>
            Creating illustrations, logos, shapes, effects, etc. using Adobe
            Illustrator.
          </li>
          <li>
            Editing and manipulating video and audio contents using Adobe
            Premier Pro.
          </li>
        </ul>
      </article>
      <article className="soft-skills">
        <h2>Soft Skills</h2>
        <ul>
          <li>Creativity</li>
          <li>Artistic Mindset</li>
          <li>Communication</li>
          <li>Planning</li>
        </ul>
      </article>
      <article className="education">
        <h2>Education</h2>
        <table>
          <thead>
            <tr>
              <th scope="col">Degree</th>
              <th scope="col">Board</th>
              <th scope="col">Institution</th>
              <th scope="col">Completed</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Bachelor of Computer Engineering</td>
              <td>Tribhuvan University</td>
              <td>National College of Engineering</td>
              <td>2018</td>
            </tr>
            <tr>
              <td>High School (+2)</td>
              <td>Higher Secondary Education Board</td>
              <td>Capital College and Research Center</td>
              <td>2012</td>
            </tr>
            <tr>
              <td>School Leaving Certificate (Grade 10)</td>
              <td>Nepal Government</td>
              <td>School Name</td>
              <td>2010</td>
            </tr>
          </tbody>
        </table>
      </article>
      <article className="gallery">
        <h2>Gallery</h2>
        <div className="photo-grid">
          <img src={Potrait1} alt="Potrait1" />
          <img src={Potrait2} alt="Potrait2" />
          <img src={Landscape1} alt="Landscape1" />
          <img src={Landscape2} alt="Landscape2" />
        </div>
      </article>
      <article className="hobbies">
        <h2>Hobbies</h2>
        <div className="hobbies">
          <div className="hobby">
            <img src={Hobby1} alt="Hobby 1" />
            <div className="card-body">
              <h4>Painting</h4>
              <hr />
              <p>
                I find joy in expressing myself through colors and creativity.
              </p>
            </div>
          </div>
          <div className="hobby">
            <img src={Hobby2} alt="Hobby 2" />
            <div className="card-body">
              <h4>Mandala Art</h4>
              <hr />
              <p>
                I find tranquility and mindfulness through intricate patterns
                and symmetry, creating visually stunning and meditative pieces.
              </p>
            </div>
          </div>
          <div className="hobby">
            <img src={Hobby3} alt="Hobby 3" />
            <div className="card-body">
              <h4>Travelling</h4>
              <hr />
              <p>
                I am passionate about traveling, as it allows me to explore new
                places, cultures, and experiences, fueling my sense of adventure
                and curiosity.
              </p>
            </div>
          </div>
        </div>
      </article>
      <footer>
        <div className="email">
          <h3>Email</h3>
          <div>
            <span className="icon">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
            <span className="email">ooshaarts@gmail.com</span>
          </div>
        </div>
        <div className="links">
          <h3>Links</h3>
          <div>
            <a
              href="https://www.instagram.com/ooshaarts/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faBehance} />
            </a>
            <a
              href="https://www.instagram.com/ooshaarts/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://www.youtube.com/@ooshaarts5762"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Demo10001;
