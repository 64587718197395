import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Course10001 from "./components/courses/Course10001";
import Demo10001 from "./components/demo/Demo10001";

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter([
  { path: "/", element: <App /> },
  { path: "/course10001", element: <Course10001 /> },
  { path: "/demo10001", element: <Demo10001 /> },
]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
