import React from "react";
import "./../styles/seven-segments.scss";

const SevenSegments = () => {
  return (
    <svg
      className="seven-segments"
      height="1500"
      strokeMiterlimit="10"
      style={{
        filRrule: "nonzero",
        clipRule: "evenodd",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      version="1.1"
      viewBox="0 0 2000 1500"
      width="2000"
    >
      <defs />
      <g id="Layer-1">
        <path
          id="seg1f"
          d="M380.004 350.001L380.004 709.997"
          fill="none"
          opacity="1"
          stroke="#2f4f4f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="40"
        />
        <path
          id="seg2f"
          d="M1180.01 350.005L1180.01 710.001"
          fill="none"
          opacity="1"
          stroke="#2f4f4f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="40"
        />
        <path
          id="seg1e"
          d="M380.004 790.03L380.004 1150.01"
          fill="none"
          opacity="1"
          stroke="#2f4f4f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="40"
        />
        <path
          id="seg2e"
          d="M1180.01 790.033L1180.01 1150.01"
          fill="none"
          opacity="1"
          stroke="#2f4f4f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="40"
        />
        <path
          id="seg1b"
          d="M820.002 350.001L820.002 709.992"
          fill="none"
          opacity="1"
          stroke="#2f4f4f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="40"
        />
        <path
          id="seg2b"
          d="M1620 350.005L1620 709.995"
          fill="none"
          opacity="1"
          stroke="#2f4f4f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="40"
        />
        <path
          id="seg1c"
          d="M820.002 790.03L820.002 1150.01"
          fill="none"
          opacity="1"
          stroke="#2f4f4f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="40"
        />
        <path
          id="seg2c"
          d="M1620 790.033L1620 1150.01"
          fill="none"
          opacity="1"
          stroke="#2f4f4f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="40"
        />
        <path
          id="seg1a"
          d="M420.001 310.005L780.005 310.005"
          fill="none"
          opacity="1"
          stroke="#2f4f4f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="40"
        />
        <path
          id="seg2a"
          d="M1220 310.008L1580.01 310.008"
          fill="none"
          opacity="1"
          stroke="#2f4f4f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="40"
        />
        <path
          id="seg1g"
          d="M420.001 750.006L780.005 750.006"
          fill="none"
          opacity="1"
          stroke="#2f4f4f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="40"
        />
        <path
          id="seg2g"
          d="M1220 750.009L1580.01 750.009"
          fill="none"
          opacity="1"
          stroke="#2f4f4f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="40"
        />
        <path
          id="seg1d"
          d="M420.001 1190L780.005 1190"
          fill="none"
          opacity="1"
          stroke="#2f4f4f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="40"
        />
        <path
          id="seg2d"
          d="M1220 1190L1580.01 1190"
          fill="none"
          opacity="1"
          stroke="#2f4f4f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="40"
        />
      </g>
    </svg>
  );
};

export default SevenSegments;
